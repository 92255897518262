<template>
	<div class="card-formulario" :class="{ 'is-expanded': isExpanded }">
		<div class="header bg-primary p-3">
			<div class="d-flex align-items-center justify-content-between w-100">
				<div class="d-flex align-items-center gap-2">
					<div class="avatar-container">
						<IconOccupied
							v-if="formulario.checkIn?.nombre"
							:user="{ nombre: formulario.checkIn?.nombre }"
							class="avatar" />
						<IconFree v-else class="avatar" />
					</div>

					<div class="info-container">
						<h5 class="nombre mb-0 text-white">
							{{ formulario.checkIn?.nombre || $t('formulario.sinNombre') }}
						</h5>
					</div>
				</div>
				<button v-if="isExpanded" class="btn-chevron" @click="toggleExpand">
					<i class="fa-solid fa-chevron-up text-white px-3"></i>
				</button>
			</div>
		</div>

		<div v-if="!isExpanded" class="estado-wrapper p-3">
			<div class="d-flex align-items-center gap-2">
				<template v-if="!isCompleted">
					<span class="estado-text me-1">{{ $t('reserva.checkIn.card.sinCompletar') }}</span>
					<button class="btn btn-primary d-flex align-items-center gap-2" @click="toggleExpand">
						<i class="fa-regular fa-pen-to-square icon-btn"></i>
						{{ $t('reserva.checkIn.card.completar') }}
					</button>
				</template>

				<template v-else>
					<span class="estado-text me-1">{{ $t('reserva.checkIn.card.guardado') }}</span>
					<button class="btn btn-secondary d-flex align-items-center gap-2 text-white" @click="toggleExpand">
						<img src="@/assets/icons/eye.svg" alt="ver" class="icon-eye" />
						{{ $t('reserva.checkIn.card.verRespuestas') }}
					</button>
				</template>
			</div>
		</div>

		<Transition name="collapse">
			<div v-show="isExpanded" class="content">
				<FormularioInscripcionReserva
					:formulario="formulario"
					:instant="instant"
					:readonly="isCompleted"
					@update-formulario="handleUpdateFormulario" />

				<div v-if="!isCompleted" class="pb-4 d-flex justify-content-center">
					<button class="btn btn-primary" :disabled="loading" @click="enviarFormulario">
						{{ $t('reserva.checkIn.card.enviar') }}
					</button>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script setup>
	import { ref, onMounted } from 'vue';
	import { createToaster } from '@meforma/vue-toaster';
	import { useI18n } from 'vue-i18n';
	import { realizarCheckIn } from '@/services/utils/FormularioInscripcionUtils';
	import IconOccupied from '@/components/Icons/IconOccupied.vue';
	import IconFree from '@/components/Icons/IconFree.vue';
	import FormularioInscripcionReserva from '@/modules/viajesGrupos/components/FormularioInscripcionReserva.vue';

	const props = defineProps({
		formulario: {
			type: Object,
			required: true,
		},
		instant: {
			type: Boolean,
			default: false,
		},
	});

	const emit = defineEmits(['update-formulario', 'formulario-enviado']);

	const { t } = useI18n();
	const toaster = createToaster({ position: 'top-right' });

	const isExpanded = ref(false);
	const currentFormData = ref(null);
	const loading = ref(false);
	const isCompleted = ref(false);

	function isFormularioCompleted(formulario) {
		if (formulario.preguntasPendiente) {
			return false;
		}

		if (props.instant) {
			if (formulario.checkInInstant && formulario.checkInPendiente) {
				return false;
			}
			if (formulario.datosPersonalesInstant && formulario.datosPersonalesPendiente) {
				return false;
			}
		} else {
			if (formulario.checkInPendiente || formulario.datosPersonalesPendiente) {
				return false;
			}
		}

		return true;
	}

	function toggleExpand() {
		isExpanded.value = !isExpanded.value;
	}

	function emitFormularioUpdate(formulario, isFormCompleted) {
		emit('update-formulario', {
			...formulario,
			isCompleted: isFormCompleted,
			pendientes: {
				preguntas: formulario.preguntasPendiente,
			},
		});
	}

	function handleUpdateFormulario(data) {
		const formularioActualizado = {
			...props.formulario,
			...data,
		};

		const isFormCompleted = isFormularioCompleted(formularioActualizado);
		currentFormData.value = {
			...data,
			isCompleted: isFormCompleted,
		};

		emitFormularioUpdate(currentFormData.value, isFormCompleted);
	}

	function updateIsCompleted() {
		const newIsCompleted = isFormularioCompleted(props.formulario);
		isCompleted.value = newIsCompleted;
		emitFormularioUpdate(props.formulario, newIsCompleted);
	}

	async function enviarFormulario() {
		if (!currentFormData.value) {
			toaster.error(t('reserva.checkIn.error.formularioIncompleto'));
			return;
		}

		const grupoId = props.formulario.grupoId;
		if (!grupoId) {
			console.error('Error: grupoId es undefined');
			toaster.error(t('reserva.checkIn.error.errorEnvio'));
			return;
		}

		try {
			loading.value = true;
			const formularioArray = [
				{
					...props.formulario,
					...currentFormData.value,
					grupoId,
				},
			];

			const success = await realizarCheckIn(formularioArray, toaster, grupoId, props.instant);
			if (success) {
				isCompleted.value = true;

				emitFormularioUpdate(
					{
						...props.formulario,
						...currentFormData.value,
					},
					true
				);

				isExpanded.value = false;
				emit('formulario-enviado', props.formulario.id);
			}
		} catch (error) {
			console.error('Error al enviar formulario:', error);
			toaster.error(t('reserva.checkIn.error.errorEnvio'));
		} finally {
			loading.value = false;
		}
	}

	onMounted(() => {
		updateIsCompleted();
	});
</script>

<style lang="scss" scoped>
	.card-formulario {
		background: white;
		transition: all 0.3s ease;

		border-radius: var(--bs-border-radius-xl);
		border: 1px solid rgba(var(--bs-primary-rgb), 0.25);
		overflow: hidden;
		margin-bottom: 1rem;

		.header {
			height: 60px;
			background: var(--bs-primary);
			padding: 1rem !important;

			.d-flex {
				width: 100%;
				min-width: 0;
			}

			.info-container {
				min-width: 0;
				flex: 1;
				margin-right: 1rem;
			}

			.nombre {
				font-size: 16px;
				font-weight: 600;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
				display: block;

				@media (max-width: 768px) {
					font-size: 13px;
				}
			}

			.avatar {
				width: 24px;
				height: 24px;
				font-size: 15px;
				flex-shrink: 0;
			}

			.btn-chevron {
				background: transparent;
				border: none;
				padding: 0;
				cursor: pointer;

				i {
					font-size: 16px;
					transition: transform 0.3s ease;
				}
			}
		}

		.estado-wrapper {
			display: flex;
			justify-content: space-between;

			align-items: center;
			border-top: 1px solid rgba(var(--bs-primary-rgb), 0.25);

			.estado-text {
				color: var(--bs-primary);
				font-size: 16px;
				font-weight: 400;

				@media (max-width: 768px) {
					font-size: 15px;
				}
			}
		}

		.content {
			border-top: 1px solid rgba(var(--bs-primary-rgb), 0.25);
		}

		.btn-primary {
			box-shadow: none !important;
			font-size: 13px;
			font-weight: 500;

			.icon-btn {
				color: var(--bs-primary);
			}

			&:hover {
				.icon-btn {
					color: var(--bs-secondary);
				}
			}
		}

		.btn-secondary {
			background-color: #212444 !important;
			border-radius: var(--bs-border-radius-xxl);
		}
	}

	:deep(.icon-occupant-active) {
		background-color: var(--bs-primary);
		border: 1px solid var(--bs-secondary);
		color: var(--bs-secondary);
	}

	.collapse-enter-active,
	.collapse-leave-active {
		transition: all 0.15s ease;
	}

	.collapse-enter-from,
	.collapse-leave-to {
		opacity: 0;
		transform: translateY(-10px);
	}
</style>
