<template>
	<svg xmlns="http://www.w3.org/2000/svg" class="qr-icon" :width="size" :height="size" viewBox="0 0 50 50" fill="none">
		<path
			d="M4.16406 18.7474V13.5391C4.16406 8.35156 8.35156 4.16406 13.5391 4.16406H18.7474"
			:stroke="color"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M31.25 4.16406H36.4583C41.6458 4.16406 45.8333 8.35156 45.8333 13.5391V18.7474"
			:stroke="color"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M45.8359 33.3359V36.4609C45.8359 41.6484 41.6484 45.8359 36.4609 45.8359H33.3359"
			:stroke="color"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M18.7474 45.8333H13.5391C8.35156 45.8333 4.16406 41.6458 4.16406 36.4583V31.25"
			:stroke="color"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M21.8776 14.5859V18.7526C21.8776 20.8359 20.8359 21.8776 18.7526 21.8776H14.5859C12.5026 21.8776 11.4609 20.8359 11.4609 18.7526V14.5859C11.4609 12.5026 12.5026 11.4609 14.5859 11.4609H18.7526C20.8359 11.4609 21.8776 12.5026 21.8776 14.5859Z"
			:stroke="color"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M38.5417 14.5859V18.7526C38.5417 20.8359 37.5 21.8776 35.4167 21.8776H31.25C29.1667 21.8776 28.125 20.8359 28.125 18.7526V14.5859C28.125 12.5026 29.1667 11.4609 31.25 11.4609H35.4167C37.5 11.4609 38.5417 12.5026 38.5417 14.5859Z"
			:stroke="color"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M21.8776 31.25V35.4167C21.8776 37.5 20.8359 38.5417 18.7526 38.5417H14.5859C12.5026 38.5417 11.4609 37.5 11.4609 35.4167V31.25C11.4609 29.1667 12.5026 28.125 14.5859 28.125H18.7526C20.8359 28.125 21.8776 29.1667 21.8776 31.25Z"
			:stroke="color"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round" />
		<path
			d="M38.5417 31.25V35.4167C38.5417 37.5 37.5 38.5417 35.4167 38.5417H31.25C29.1667 38.5417 28.125 37.5 28.125 35.4167V31.25C28.125 29.1667 29.1667 28.125 31.25 28.125H35.4167C37.5 28.125 38.5417 29.1667 38.5417 31.25Z"
			:stroke="color"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round" />
	</svg>
</template>

<script setup></script>

<style lang="scss" scoped>
	.qr-icon path {
		stroke: var(--bs-primary);
	}
</style>
