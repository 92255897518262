<template>
	<div class="bg-white">
		<div class="form-status-header">
			<span class="form-status-text">
				{{ readonly ? $t('reserva.checkIn.card.guardado') : $t('reserva.checkIn.card.sinCompletar') }}
			</span>
		</div>
		<div class="card-body pb-2 px-4">
			<!-- Documentación -->
			<div v-if="showDocumentacionForm || !formulario.checkInPendiente">
				<div class="row g-4">
					<!-- Nacionalidad -->
					<div class="col-12 col-md-6">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.nacionalidad') }}</label>
						<select
							v-model="formularioData.formDataDocumentacion.nacionalidad"
							class="form-select bg-light border-0 py-3"
							:disabled="!formulario.checkInPendiente">
							<option value="null">{{ $t('reserva.checkIn.placeholders.seleccionaNacionalidad') }}</option>
							<option v-for="(name, code) in countryList" :key="code" :value="code">{{ name }}</option>
						</select>
					</div>

					<!-- Fecha Nacimiento -->
					<div class="col-12 col-md-6">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.fechaNacimiento') }}</label>
						<input
							v-model="formularioData.formDataDocumentacion.fechaNacimiento"
							type="date"
							class="form-control bg-light border-0 py-3"
							:disabled="!formulario.checkInPendiente"
							:placeholder="$t('reserva.checkIn.placeholders.fecha')" />
					</div>

					<!-- Lugar Nacimiento -->
					<div class="col-12 col-md-6">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.lugarNacimiento') }}</label>
						<input
							v-model="formularioData.formDataDocumentacion.lugarNacimiento"
							class="form-control bg-light border-0 py-3"
							:disabled="!formulario.checkInPendiente"
							:placeholder="$t('reserva.checkIn.placeholders.escribeTuRespuesta')" />
					</div>

					<!-- DNI/Pasaporte -->
					<div class="col-12 col-md-6">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.dni') }}</label>
						<input
							v-model="formularioData.formDataDocumentacion.dni"
							class="form-control bg-light border-0 py-3"
							:disabled="!formulario.checkInPendiente"
							placeholder="AAA000000" />
					</div>

					<!-- Número Soporte DNI -->
					<div class="col-12 col-md-6">
						<label class="form-label text-primary small mb-2 d-flex align-items-center">
							{{ $t('reserva.checkIn.soporteDni') }}
							<div class="pointer d-flex align-items-center" @click="showInfoSoporte">
								<InfoIcon class="custom-svgInfo" size="12px" :message-info="t('reserva.checkIn.info.numSoporteDni')" />
							</div>
						</label>
						<input
							v-model="formularioData.formDataDocumentacion.numSoporteDni"
							maxlength="9"
							class="form-control bg-light border-0 py-3"
							:disabled="!formulario.checkInPendiente"
							placeholder="IDESP000000000" />
					</div>

					<!-- Fecha Caducidad -->
					<div class="col-12 col-md-6">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.fechaCaducidad') }}</label>
						<input
							v-model="formularioData.formDataDocumentacion.fechaCaducidadDni"
							type="date"
							class="form-control bg-light border-0 py-3"
							:disabled="!formulario.checkInPendiente"
							placeholder="dd/mm/aa" />
					</div>

					<!-- Fecha Expedición -->
					<div class="col-12 col-md-6">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.fechaExpedicion') }}</label>
						<input
							v-model="formularioData.formDataDocumentacion.fechaExpedicionDni"
							type="date"
							class="form-control bg-light border-0 py-3"
							:disabled="!formulario.checkInPendiente"
							placeholder="dd/mm/aa" />
					</div>

					<!-- País -->
					<div class="col-12">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.pais') }}</label>
						<select
							v-model="formularioData.formDataDocumentacion.pais"
							class="form-select bg-light border-0 py-3"
							:disabled="!formulario.checkInPendiente">
							<option value="null">{{ $t('reserva.checkIn.placeholders.seleccionaPais') }}</option>
							<option v-for="(name, code) in countryList" :key="code" :value="code">{{ name }}</option>
						</select>
					</div>

					<!-- Dirección -->
					<div class="col-12 col-md-8">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.direccion') }}</label>
						<input
							v-model="formularioData.formDataDocumentacion.direccion"
							class="form-control bg-light border-0 py-3"
							:disabled="!formulario.checkInPendiente"
							:placeholder="$t('reserva.checkIn.placeholders.escribeTuRespuesta')" />
					</div>

					<!-- Código Postal -->
					<div class="col-12 col-md-4">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.codigoPostal') }}</label>
						<input
							v-model="formularioData.formDataDocumentacion.codigoPostal"
							class="form-control bg-light border-0 py-3"
							:disabled="!formulario.checkInPendiente"
							:placeholder="$t('reserva.checkIn.placeholders.escribeTuRespuesta')" />
					</div>

					<div v-if="!formulario?.checkIn.dniSubido" class="col-12 col-md-6">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.anversoDni') }}</label>
						<div class="file-input-wrapper">
							<button
								v-if="!formularioData.formDataDocumentacion.anversoDni"
								class="file-input-button"
								@click="anversoDniInput.click()">
								<span>{{ $t('reserva.checkIn.seleccionarDocumento') }}</span>
								<UploadIcon class="ms-1" />
							</button>
							<button
								v-if="formularioData.formDataDocumentacion.anversoDni"
								class="file-input-button"
								@click="fileDeletedHandler('anversoDni', anversoDniInput)">
								<span>{{ formularioData.formDataDocumentacion.anversoDni.name }}</span>
								<i class="fas fa-trash"></i>
							</button>
							<input
								ref="anversoDniInput"
								type="file"
								accept="image/jpeg, image/png"
								class="d-none"
								:disabled="!formulario.checkInPendiente"
								@change="fileChangeHandler($event, 'anversoDni')" />
						</div>
					</div>

					<!-- Reverso DNI -->
					<div v-if="!formulario?.checkIn.dniSubido" class="col-12 col-md-6">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.reversoDni') }}</label>
						<div class="file-input-wrapper">
							<button
								v-if="!formularioData.formDataDocumentacion.reversoDni"
								class="file-input-button"
								@click="reversoDniInput.click()">
								<span>{{ $t('reserva.checkIn.seleccionarDocumento') }}</span>
								<UploadIcon class="ms-1" />
							</button>
							<button
								v-if="formularioData.formDataDocumentacion.reversoDni"
								class="file-input-button"
								@click="fileDeletedHandler('reversoDni', reversoDniInput)">
								<span>{{ formularioData.formDataDocumentacion.reversoDni.name }}</span>
								<i class="fas fa-trash"></i>
							</button>
							<input
								ref="reversoDniInput"
								type="file"
								accept="image/jpeg, image/png"
								class="d-none"
								:disabled="!formulario.checkInPendiente"
								@change="fileChangeHandler($event, 'reversoDni')" />
						</div>
					</div>

					<!-- Sexo -->
					<div class="col-12">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.sexo.title') }}</label>
						<div class="d-flex flex-column gap-2">
							<div class="form-check">
								<input
									id="sexo-hombre{{formularioData.id}}"
									v-model="formularioData.formDataDocumentacion.sexo"
									class="form-check-input"
									type="radio"
									value="hombre"
									:disabled="!formulario.checkInPendiente" />
								<label class="form-check-label" for="sexo-hombre{{formularioData.id}}">{{
									$t('reserva.checkIn.sexo.hombre')
								}}</label>
							</div>
							<div class="form-check">
								<input
									id="sexo-mujer{{formularioData.id}}"
									v-model="formularioData.formDataDocumentacion.sexo"
									class="form-check-input"
									type="radio"
									value="mujer"
									:disabled="!formulario.checkInPendiente" />
								<label class="form-check-label" for="sexo-mujer{{formularioData.id}}">{{
									$t('reserva.checkIn.sexo.mujer')
								}}</label>
							</div>
						</div>
					</div>

					<!-- Llegada -->
					<div class="col-12 col-md-6">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.llegada') }}</label>
						<input
							v-model="formularioData.formDataDocumentacion.horaCheckIn"
							type="datetime-local"
							class="form-control bg-light border-0 py-3"
							:disabled="!formulario.checkInPendiente"
							@change="completeDatetime('horaCheckIn')" />
					</div>

					<!-- Salida -->
					<div class="col-12 col-md-6">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.salida') }}</label>
						<input
							v-model="formularioData.formDataDocumentacion.horaCheckOut"
							type="datetime-local"
							class="form-control bg-light border-0 py-3"
							:disabled="!formulario.checkInPendiente"
							@change="completeDatetime('horaCheckOut')" />
					</div>
				</div>
			</div>

			<!-- Datos Personales -->
			<div v-if="showPersonalForm || !formulario.datosPersonalesPendiente" class="mt-4">
				<div class="row g-4">
					<!-- Alergias -->
					<div class="col-12 col-md-6">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.alergias') }}</label>
						<input
							v-model="formularioData.formDataPersonal.alergias"
							class="form-control bg-light border-0 py-3"
							:disabled="!formulario.datosPersonalesPendiente"
							:placeholder="$t('reserva.checkIn.placeholders.escribeTuRespuesta')" />
					</div>

					<!-- Altura -->
					<div v-if="formulario?.hasAlquiler" class="col-12 col-md-6">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.altura') }}</label>
						<input
							v-model="formularioData.formDataPersonal.altura"
							type="number"
							class="form-control bg-light border-0 py-3"
							:disabled="!formulario.datosPersonalesPendiente"
							:placeholder="$t('reserva.checkIn.placeholders.escribeTuRespuesta')" />
					</div>

					<!-- Peso -->
					<div v-if="formulario?.hasAlquiler" class="col-12 col-md-6">
						<div class="col-12 col-md-6">
							<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.peso') }}</label>
							<div class="input-group">
								<input
									v-model="formularioData.formDataPersonal.peso"
									type="number"
									class="form-control bg-light border-0 py-3"
									:disabled="!formulario.datosPersonalesPendiente"
									placeholder="0" />
								<span class="input-group-text bg-light border-0">Kg</span>
							</div>
						</div>
					</div>

					<!-- Talla de pie -->
					<div v-if="formulario?.hasAlquiler" class="col-12 col-md-6">
						<label class="form-label text-primary small mb-2">{{ $t('reserva.checkIn.tallaPie') }}</label>
						<input
							v-model="formularioData.formDataPersonal.tallaPie"
							type="number"
							class="form-control bg-light border-0 py-3"
							:disabled="!formulario.datosPersonalesPendiente"
							:placeholder="$t('reserva.checkIn.placeholders.escribeTuRespuesta')" />
					</div>
				</div>
			</div>

			<!-- Preguntas -->
			<div v-if="showPreguntas || !formulario.preguntasPendiente" class="mt-4">
				<div v-for="pregunta in viaje.preguntas" :key="pregunta.id" class="pregunta-input">
					<div class="col-12 mb-4 text-primary input-container">
						<label class="label-input translate" :for="`pregunta_${pregunta.id}`">
							{{ pregunta.pregunta }}
						</label>
						<input
							v-model="formularioData.formDataPreguntas.preguntas.find(item => item.id === pregunta.id).respuesta"
							class="form-control text-start"
							:name="`pregunta_${pregunta.id}`"
							:placeholder="t('subtitulo.escribeRespuesta')"
							type="text"
							:disabled="!formulario.preguntasPendiente" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, reactive, watch, onBeforeMount, computed } from 'vue';
	import countries from 'i18n-iso-countries';
	import enLocale from 'i18n-iso-countries/langs/en.json';
	import esLocale from 'i18n-iso-countries/langs/es.json';
	import frLocale from 'i18n-iso-countries/langs/fr.json';
	import itLocale from 'i18n-iso-countries/langs/it.json';
	import Cookies from 'js-cookie';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import debounce from 'lodash/debounce';
	import { cloneDeep } from 'lodash';
	import { useI18n } from 'vue-i18n';
	import InfoIcon from '@/components/Icons/InfoIcon.vue';
	import UploadIcon from '@/components/Icons/UploadIcon.vue';
	import { comprimirImagen } from '@/helpers/imageHelpers';
	import { createToaster } from '@meforma/vue-toaster';

	const props = defineProps({
		formulario: {
			type: Object,
			required: true,
		},
		instant: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	});

	const emits = defineEmits(['updateFormulario']);
	const { t } = useI18n();
	const toaster = createToaster({ position: 'top-right' });

	const storeHabitacion = informacionHabitacion();
	const viaje = storeHabitacion.getViaje;
	const countryList = ref({});
	const anversoDniInput = ref(null);
	const reversoDniInput = ref(null);

	const showDocumentacionForm = computed(
		() => (!props.instant || viaje.checkInInstant) && props.formulario?.checkInPendiente
	);

	const showPersonalForm = computed(
		() =>
			viaje.datosPersonalesEnabled &&
			(!props.instant || viaje.datosPersonalesInstant) &&
			props.formulario?.datosPersonalesPendiente
	);
	const showPreguntas = computed(() => props.formulario?.preguntasPendiente);
	const showFormulario = computed(
		() => showDocumentacionForm.value || showPersonalForm.value || showPreguntas.value
	);

	const formularioData = reactive({
		id: props.formulario?.id,
		nombre: props.formulario?.checkIn.nombre,
		showed: showFormulario.value,
		checkInPendiente: props.formulario?.checkInPendiente,
		checkInInstant: viaje.checkInInstant,
		dniSubido: props.formulario?.checkIn.dniSubido,
		hasAlquiler: props.formulario?.hasAlquiler,
		datosPersonalesPendiente: props.formulario?.datosPersonalesPendiente,
		datosPersonalesEnabled: viaje.datosPersonalesEnabled,
		datosPersonalesInstant: viaje.datosPersonalesInstant,
		preguntasPendiente: props.formulario?.preguntasPendiente,
		formDataDocumentacion: {
			nacionalidad: props.formulario?.checkIn.nacionalidad ?? null,
			fechaNacimiento: props.formulario?.checkIn.fechaNacimiento || null,
			lugarNacimiento: props.formulario?.checkIn.lugarNacimiento || null,
			dni: props.formulario?.checkIn.dni || null,
			numSoporteDni: props.formulario?.checkIn.numSoporteDni || null,
			fechaCaducidadDni: props.formulario?.checkIn.fechaCaducidadDni || null,
			fechaExpedicionDni: props.formulario?.checkIn.fechaExpedicionDni || null,
			pais: props.formulario?.checkIn.pais ?? null,
			direccion: props.formulario?.checkIn.direccion || null,
			codigoPostal: props.formulario?.checkIn.codigoPostal || null,
			sexo: props.formulario?.checkIn.sexo || null,
			horaCheckIn: props.formulario?.checkIn.horaCheckIn || null,
			horaCheckOut: props.formulario?.checkIn.horaCheckOut || null,
			anversoDni: props.formulario?.checkIn.anversoDni || null,
			reversoDni: props.formulario?.checkIn.reversoDni || null,
		},
		formDataPersonal: {
			alergias: props.formulario?.datosPersonales.alergias || null,
			altura: props.formulario?.datosPersonales.altura || null,
			peso: props.formulario?.datosPersonales.peso || null,
			tallaPie: props.formulario?.datosPersonales.tallaPie || null,
		},
		formDataPreguntas: {
			preguntas:
				viaje.preguntas?.map(pregunta => {
					const preguntaExistente = props.formulario?.preguntas?.preguntas?.find(p => p.id === pregunta.id);
					return {
						id: pregunta.id,
						respuesta: preguntaExistente?.respuesta ?? null,
					};
				}) || [],
		},
	});

	function completeDatetime(field) {
		if (formularioData.formDataDocumentacion[field] !== null) {
			formularioData.formDataDocumentacion[field] += ':00';
		}
	}

	async function fileChangeHandler(event, field) {
		const file = event.target.files[0];
		const compressedFile = await comprimirImagen(file, 40);
		if (compressedFile.size > 2097152) {
			toaster.error(`${t('home.form.tamañoMaximo')} 2mb`);
			event.target.value = null;
			return;
		}
		if (compressedFile) {
			formularioData.formDataDocumentacion[field] = new File([compressedFile], file.name, {
				type: file.type,
				lastModified: Date.now(),
			});
		}
	}

	async function fileDeletedHandler(field, input) {
		formularioData.formDataDocumentacion[field] = null;
		input.value = null;
	}

	watch(
		formularioData,
		debounce(newValue => {
			emits('updateFormulario', cloneDeep(newValue));
		}, 500)
	);

	onBeforeMount(() => {
		countries.registerLocale(esLocale);
		countries.registerLocale(enLocale);
		countries.registerLocale(frLocale);
		countries.registerLocale(itLocale);
		countryList.value = Object.entries(countries.getNames(Cookies.get('lang')))
			.sort(([, a], [, b]) => a.localeCompare(b))
			.reduce((sorted, [code, name]) => {
				sorted[code] = name;
				return sorted;
			}, {});
		emits('updateFormulario', cloneDeep(formularioData));
	});
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/main.scss';

	.input-container {
		.label-input {
			margin-bottom: 0.5rem;
			font-size: 16px;
			font-weight: 500;

			@include media-breakpoint-down(md) {
				font-size: 13px;
			}
		}

		&.input-error {
			.label-input {
				color: #dc3545;
			}

			.form-control,
			.form-select-validate {
				border: solid 2px #dc3545;

				&:focus {
					outline: 0;
					box-shadow: 0 0 0 0.25rem rgba(255, 10, 30, 0.25);
				}
			}
		}
	}
	.form-label {
		font-weight: 500;
		font-size: 16px;
	}

	.card-resumen {
		border-radius: var(--bs-border-radius-xl);
		border: 1px solid rgba(33, 36, 68, 0.25);

		.card-body {
			padding: 1rem 2rem;
		}
	}

	.title-name {
		font-size: 20px;
		font-weight: 600;
		color: #212444;
	}

	.container {
		max-width: 100%;
		width: 100%;
		margin: 0 auto;
		padding: 20px;
	}

	.btn-upload {
		padding: 8px 16px;
		background-color: #f0f0f0;
		border: 1px solid #212444;
		border-radius: 4px;
		cursor: pointer;
		color: #333;
	}

	.radio-group {
		display: flex;
		gap: 20px;
	}

	.radio-group label {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.datetime-group {
		display: flex;
		gap: 10px;
	}

	.datetime-group input {
		flex: 1;
	}

	.radio-options {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.radio-option {
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}

	.radio-circle {
		flex: 0 0 auto;
	}

	.radio-circle input[type='radio'] {
		margin: 0;
		width: 16px;
		height: 16px;
		cursor: pointer;
	}

	.radio-option span {
		color: #212444;
		font-size: 0.9rem;
	}

	.conditional-input {
		margin: 0.5rem 0 0.5rem 20px;
		width: calc(100% - 20px);
	}

	.conditional-input input {
		width: 100%;
		padding: 8px 12px;
		border: 1px solid #ddd;
		border-radius: 4px;
		font-size: 14px;
		background-color: #f8f8f8;
	}

	.file-input-wrapper {
		position: relative;
		display: inline-block;
		width: 100%;
	}

	.file-input {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
		z-index: 2;
	}

	.file-input-button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 20px;
		border: 1px solid var(--bs-primary);
		border-radius: 25px;
		background: white;
		color: var(--bs-primary);
		width: 100%;
		cursor: pointer;
		transition: all 0.3s ease;

		span {
			color: var(--bs-primary);
		}

		i,
		:deep(svg) {
			color: var(--bs-primary);
			margin-left: 10px;
		}

		&:hover {
			background-color: rgba(var(--bs-primary-rgb), 0.05);
		}
	}

	.form-control,
	.form-select {
		background-color: rgba(32, 36, 68, 0.02) !important;
		box-shadow: 0 0 3px 0 rgba(32, 36, 68, 0.35) inset !important;
		border: none !important;
		height: 40px !important;
		padding: 0.375rem 0.75rem !important;
		text-align: left !important;
	}

	.form-check {
		.form-check-input[type='radio'] {
			cursor: pointer;
			border: 1px solid rgba(var(--bs-primary-rgb), 0.3);

			&:checked {
				background-color: var(--bs-secondary);
				border-color: var(--bs-primary);
			}

			&:focus {
				box-shadow: none;
			}
		}
	}

	:deep(.tooltip-left) {
		width: 10vw;

		@media (max-width: 768px) {
			font-size: 11px;
		}
	}

	.form-status-header {
		padding: 1rem;
		border-bottom: 1px solid rgba(var(--bs-primary-rgb), 0.25);
		margin-bottom: 1rem;

		.form-status-text {
			font-size: 16px;
			color: var(--bs-primary);
		}
	}

	.form-control,
	.form-select {
		&:disabled {
			background-color: white !important;
			opacity: 0.7 !important;
		}
	}

	@media screen and (max-width: 768px) {
		.form-label,
		.form-control,
		.form-select,
		.form-check-label,
		input::placeholder,
		select,
		.input-group-text {
			font-size: 13px !important;
		}

		.form-status-header {
			.form-status-text {
				font-size: 15px;
			}
		}
	}
</style>
